const defaultStructure = {
  donor_threshold: ['Select Donor Threshold', 'Donor Floor', 'Donor Target'],
  donor_category: ['Select Donor Category', 'All', 'Political', 'Non-Profit'],
  client_type: ['Select Client Type', 'political', 'nonprofit'],
  curate_frequency: ['Select Frequency', 'weekly'],
  email_validation_provider: ['Select Email Validation Provider', 'briteverify', 'kickbox'],
  action_platform: [
    'Select Data Source',
    'actionkit',
    'bigquery',
    'bsd',
    'cheetahmail',
    'custom',
    'engaging_networks',
    'flat_file',
    'hub',
    'mailchimp',
    'ngp_everyaction',
    'quorum',
    'salesforce',
    'salsa',
    'salsa_engage',
    'actionkit, hub',
    'engaging_networks, ngp_everyaction',
  ],
  people_platform: [
    'Select Data Source',
    'action_network',
    'actionkit',
    'bigquery',
    'bsd',
    'campaign_monitor',
    'cheetahmail',
    'civis',
    'constant_contact',
    'custom',
    'engaging_networks',
    'flat_file',
    'hub',
    'hubspot',
    'luminate',
    'mailchimp',
    'marketo',
    'nationbuilder',
    'ngp_everyaction',
    'pardot',
    'quorum',
    'raisers_edge',
    'salesforce',
    'salsa',
    'salsa_engage',
    'sfmc',
    'actionkit, hub',
    'actionkit, ngp_everyaction',
    'civis, sfmc',
    'engaging_networks, ngp_everyaction',
    'flat_file, hubspot',
    'flat_file, sfmc',
    'luminate, sfmc',
    'luminate, marketo',
    'mailchimp, nationbuilder',
    'mailchimp, ngp_everyaction',
    'mailchimp, raisers_edge',
  ],
  transaction_platform: [
    'Select Data Source',
    'actblue',
    'actionkit',
    'bigquery',
    'bsd',
    'civis',
    'cheetahmail',
    'classy',
    'custom',
    'engaging_networks',
    'flat_file',
    'fundraise_up',
    'funraise',
    'hub',
    'kindest',
    'luminate',
    'ngp_everyaction',
    'raisers_edge',
    'salesforce',
    'salsa',
    'salsa_engage',
    'actblue, actionkit',
    'actblue, civis',
    'actblue, ngp_everyaction',
    'actblue, salsa',
    'engaging_networks, luminate',
    'engaging_networks, ngp_everyaction',
    'fundraise_up, luminate',
    'mailchimp, ngp_everyaction',
  ],
  exhaust_platform: [
    'Select Data Source',
    'acoustic',
    'acoustic_trilogy',
    'action_network',
    'actionkit',
    'bigquery',
    'bsd',
    'campaign_monitor',
    'cheetahmail',
    'civicrm',
    'constant_contact',
    'custom',
    'engaging_networks',
    'flat_file',
    'hubspot',
    'luminate',
    'luminate_ftp',
    'mailchimp',
    'marketo',
    'ngp_everyaction',
    'pardot',
    'quorum',
    'salsa',
    'salsa_engage',
    'sfmc',
    'actionkit, acoustic_trilogy',
    'actionkit, ngp_everyaction',
    'action_network, mailchimp',
    'cheetahmail, sfmc',
    'engaging_networks, luminate',
    'engaging_networks, ngp_everyaction',
    'flat_file, hubspot',
    'flat_file, sfmc',
    'luminate, marketo',
    'mailchimp, ngp_everyaction',
  ],
  sms_platform: ['Select Data Source', 'tatango', 'scale_to_win', 'scale_to_win, tatango'],
  transactions_offline_platform: ['Select Data Source', 'flat_file', 'raisers_edge', 'salesforce'],
  timezone: ['Select Client Timezone', 'America_New_York'],
  fiscal_year_start: ['Select Month', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
};

// used when the user selects a value that should reset the field to an empty string or array
const resetFieldValues = [
  'Select Data Source',
  'Select Donor Threshold',
  'Select Donor Category',
  'Select Email Validation Provider',
  'Select Frequency',
  'Select Client Timezone',
  'Select Month',
];

// if array of numbers, include the element in arrayFields as well
const intFields = [
  'actblue_entity_ids',
  'curate_days',
  'sfg_surveys_client_ids',
  'goop_client_ids',
  'totem_client_ids',
  'tatango_client_id',
  'share_cap_max_shares',
  'share_cap_months',
  'donor_prop',
];

// if array of numbers, include the element in intFields as well
const arrayFields = [
  'actblue_entity_ids',
  'facebook_account_ids',
  'google_account_ids',
  'people_platform',
  'action_platform',
  'transaction_platform',
  'exhaust_platform',
  'sms_platform',
  'transactions_offline_platform',
  'suppressed_domains',
  'facebook_locations',
  'bing_account_ids',
  'twitter_account_ids',
  'outbrain_account_ids',
  'ngp_actblue_manual_codes',
  'multi_member_clients',
  'multi_member_sms_clients',
  'stw_subaccount_ids',
  'goop_client_ids',
  'totem_client_ids',
  'inbox_monster_rule',
];

export {
  defaultStructure, resetFieldValues, intFields, arrayFields,
};
