import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';

import ClientCheckBox from '../clientFields/ClientCheckbox';
import ClientTextField from '../clientFields/ClientTextField';
import ClientDropDown from '../clientFields/ClientDropDown';
import { formatDate } from '../../utils/formatters';
import ClientTextarea from '../clientFields/ClientTextarea';

function AdAITab({
  validateFunc,
  setFunction,
  data,
  hasTDCAdminPermission,
  structure,
  timeStamps,
  permissions = {},
  createdAt,
}) {
  const setter = setFunction;
  const [liveData, setLiveData] = useState({ ...data });
  const [userPermissions, setUserPermissions] = useState({ ...permissions });
  const [dataStructure, setDataStructure] = useState({ ...structure });
  const [formattedIsAdAIMember, setFormattedIsAdAIMember] = useState(null);

  useEffect(() => {
    setLiveData(data);
    setUserPermissions(permissions);
    setDataStructure(structure);
  }, [data]);

  useEffect(() => {
    if (timeStamps && timeStamps.is_advantage_member) {
      setFormattedIsAdAIMember(formatDate(timeStamps.is_advantage_member));
    }
    setLiveData(data);
    setDataStructure(structure);
  }, [data, timeStamps]);

  return (
    <div id="adai-columns">
      <div id="adai-info">
        <div className="timestamp">
          <ClientCheckBox
            setFunction={setter}
            data={liveData.is_advantage_member}
            attribute="is_advantage_member"
            label="Advantage AI Member"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
            testingId="adaicheckbox-test"
          />
          {/* display last timestamp update or when the client was created */}
          <p>
            Last updated on
            {' '}
            {timeStamps.is_advantage_member ? formattedIsAdAIMember : (formatDate(createdAt))}
          </p>
        </div>
        <ClientCheckBox
          setFunction={setter}
          data={liveData.is_mid_level_member}
          attribute="is_mid_level_member"
          label="Mid-Level Co-Op Member"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
          testingId="mid-level-checkbox-test"
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.advantageai_member_on_date}
          attribute="advantageai_member_on_date"
          label="Advantage AI Member Since"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.suppressed_domains}
          attribute="suppressed_domains"
          label="Suppressed Domains"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.share_cap_max_shares}
          attribute="share_cap_max_shares"
          label="Share Cap Max Shares"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.share_cap_months}
          attribute="share_cap_months"
          label="Share Cap Months"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextarea
          validateFunc={validateFunc}
          setFunction={setter}
          data={liveData.notes}
          attribute="notes"
          label="Notes"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientCheckBox
          setFunction={setter}
          data={liveData.limited_model_universe}
          attribute="limited_model_universe"
          label="Limited Model Universe"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
          testingId="limited_model_universe-checkbox-test"
        />
      </div>
      <div id="delivery-cards">
        <Card className="client-card" variant="outlined" sx={{ p: 3 }}>
          <h2>Email Donor Proportion</h2>
          <ClientDropDown
            setFunction={setter}
            data={liveData.donor_threshold}
            attribute="donor_threshold"
            label="Donor Prop Threshold"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.donor_category}
            attribute="donor_category"
            label="Donor Category"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <ClientTextField
            validateFunc={validateFunc}
            setFunction={setter}
            data={liveData.donor_prop}
            attribute="donor_prop"
            label="Donor Prop"
            permissions={userPermissions}
            structure={dataStructure}
            inputType="number"
          />
        </Card>
        <Card className="adai-email-validation-provider" variant="outlined" sx={{ p: 3 }}>
          <h2>Email Validation Provider</h2>
          <ClientDropDown
            setFunction={setter}
            data={liveData.email_validation_provider}
            attribute="email_validation_provider"
            label="Email Validation Provider"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
        </Card>
        <Card
          id="adai-delivery-selections"
          className="client-card"
          variant="outlined"
          sx={{ p: 3 }}
        >
          <h2>Multi-Member Configuration</h2>
          <p>Multi-Member Clients</p>
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_clients
              ? liveData.multi_member_clients[0]
              : null}
            attribute="multi_member_clients"
            label="MM Client 1"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_clients
              ? liveData.multi_member_clients[1]
              : null}
            attribute="multi_member_clients"
            label="MM Client 2"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_clients
              ? liveData.multi_member_clients[2]
              : null}
            attribute="multi_member_clients"
            label="MM Client 3"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />

          <p>Multi-Member SMS Clients</p>
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_sms_clients
              ? liveData.multi_member_sms_clients[0]
              : null}
            attribute="multi_member_sms_clients"
            label="MM SMS Client 1"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_sms_clients
              ? liveData.multi_member_sms_clients[1]
              : null}
            attribute="multi_member_sms_clients"
            label="MM SMS Client 2"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_sms_clients
              ? liveData.multi_member_sms_clients[2]
              : null}
            attribute="multi_member_sms_clients"
            label="MM SMS Client 3"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
        </Card>
      </div>
    </div>
  );
}

export default AdAITab;

AdAITab.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.object]),
  structure: PropTypes.oneOfType([PropTypes.object]),
  setFunction: PropTypes.func,
  validateFunc: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeStamps: PropTypes.oneOfType([PropTypes.object]),
  hasTDCAdminPermission: PropTypes.bool.isRequired,
  createdAt: PropTypes.string,
};
