import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import APIWrapper from '../utils/graphqlwrapper';
import AppContext from '../context';
import logger from '../utils/logger';
import Table from '../components/Table';
// eslint-disable-next-line no-unused-vars
import {
  getLocalStorageTablePref, getRowIdxForId, updateTablePreferenceLocalStorage,
  tableColumnPreferenceLoader,
} from '../utils';
import {
  addFavoriteClient, checkIfFavorite, getFavoriteClients, removeFavoriteClient,
} from '../utils/favsAndRecentClients';
import FavButton from '../components/FavButton';
import SettingsButton from '../components/SettingsButton';
import { clientColumns } from '../utils/schemaInfo';
import { createClientRows } from '../utils/tableHelpers';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const initColumnVisibilityModel = {
  bingids: false,
  campaignmonitorlocation: false,
  classyorgid: false,
  codedicurl: false,
  curateautoupload: false,
  curatedays: false,
  curatefrequency: false,
  curatesubscriber: false,
  donorfloortype: false,
  donorfloorvalue: false,
  excludematchqualifier: false,
  facebookids: false,
  facebookloc: false,
  fiscalyearstart: false,
  googleids: false,
  goopid: false,
  hubspotstitchintegrationname: false,
  hubsrcpath: false,
  inboxmonsterrule: false,
  islowinventory: false,
  limitedmodeluniverse: false,
  mailchimpdir: false,
  mmclients: false,
  mmrequired: false,
  mmsmsclients: false,
  ngpactbluemanualcodes: false,
  ngpeadb: false,
  ngpeaserver: false,
  notes: false,
  outbrainids: false,
  pardotloc: false,
  pausedata: false,
  salesforceloc: false,
  salsakey: false,
  sfgid: false,
  sharecapmaxshares: false,
  sharecapmonths: false,
  slackchannel: false,
  smsdatasource: false,
  stwaccountids: false,
  tatangoid: false,
  timezone: false,
  totemid: false,
  twitterids: false,
  updatedat: false,
  createdat: false,
};

const defaultSortModel = [{ field: 'code', sort: 'asc' }];

function ClientList(props) {
  const {
    setHeader, hasTDCAdminPermission, hasDataEngineerPermission, hasAnalystPermission,
  } = props;
  const navigate = useNavigate();
  const [clientData, setClientData] = useState([]);
  const { setFavClients } = useContext(AppContext);

  const [graphqlNextToken, setGraphqlNextToken] = useState('');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initColumnVisibilityModel,
  );
  const [pageState, setPageState] = useState({
    isLoading: true,
    page: 0, // mui pages are 0 indexed
    pageSize: 50,
    data: [],
    allClients: [],
    total: 0,
    favoriteClients: [],
  });
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState(defaultSortModel);

  const hasFetchedData = useRef(false);
  const pageSetter = setHeader;
  const { setShouldLogOut, authenticated } = useContext(AppContext);
  // REFACTOR temp workaround to restrict client create/edit permissions
  const hasClientCreateEditPermission = hasTDCAdminPermission;

  const prevData = useRef(pageState.data.concat(pageState.favoriteClients));
  useEffect(() => {
    prevData.current = pageState.data.concat(pageState.favoriteClients);
  }, [pageState.data.concat(pageState.favoriteClients)]);

  const handleToggleFav = (event, id) => {
    let realId;
    if (event.row) {
      realId = event.id;
    } else {
      event.stopPropagation();
      realId = id;
    }
    const currData = prevData.current;
    const idx = getRowIdxForId(realId, currData);
    const selectedClient = currData[idx].code;
    const isFav = checkIfFavorite(selectedClient);
    if (isFav) {
      removeFavoriteClient(selectedClient);
    } else {
      addFavoriteClient(selectedClient);
    }
    setFavClients(getFavoriteClients());
  };

  const actionCol = [
    {
      field: 'favorite',
      type: 'actions',
      headerName: 'Favorite',
      disableClickEventBubbling: true,
      cellClassName: 'favorite',
      getActions: ({ id }) => [
        <FavButton
          toggleFav={handleToggleFav}
          id={id}
          data={prevData.current}
        />,
      ]
      ,
    },
  ];

  if (hasTDCAdminPermission || hasDataEngineerPermission || hasAnalystPermission) {
    actionCol.push({
      field: 'settings',
      type: 'actions',
      headerName: 'Edit',
      disableClickEventBubbling: true,
      cellClassName: 'settings',
      getActions: ({ id }) => [
        <SettingsButton
          id={id}
          data={prevData.current}
        />,
      ],
    });
  }

  const columns = actionCol.concat(clientColumns);

  const [orderedColumns, setOrderedColumns] = useState(columns);

  const customSetOrdered = (neworder) => {
    updateTablePreferenceLocalStorage('clientlist', 'column', neworder);
  };

  useEffect(() => {
    document.title = 'MissionPortal | Client List';
    const clientListFilters = getLocalStorageTablePref('clientlist', 'filter');
    if (clientListFilters) {
      setFilterModel(clientListFilters);
    }
    const clientListSortOrder = getLocalStorageTablePref('clientlist', 'sort');
    if (!clientListSortOrder) {
      updateTablePreferenceLocalStorage('clientlist', 'sort', defaultSortModel);
    } else {
      setSortModel(clientListSortOrder);
    }
    const clientListColumnVisibility = getLocalStorageTablePref('clientlist', 'column-visibility');
    if (!clientListColumnVisibility) {
      updateTablePreferenceLocalStorage('clientlist', 'column-visibility', initColumnVisibilityModel);
    } else {
      setColumnVisibilityModel(clientListColumnVisibility);
    }
  }, []);

  useEffect(() => {
    tableColumnPreferenceLoader('clientlist', 'column', columns, setOrderedColumns);
  }, [pageState]);

  useEffect(() => {
    if (typeof pageSetter === 'function') {
      pageSetter(['Client List']);
    }
  }, [pageSetter]);

  useEffect(() => {
    // pull initial data and format.

    // query API for all clients, if next token is returned, save it, if nextToken is set, use it
    const fetchClients = async (token = graphqlNextToken) => {
      const clients = await APIWrapper.queryApi(
        { query: 'listClients' },
        setShouldLogOut,
        { limit: 500, nextToken: token ? graphqlNextToken : null },
      )
        .then((data) => data)
        .catch((e) => {
          logger.error(e);
        });
      setClientData(clients.data);
      setGraphqlNextToken(clients.nextToken);
      const { formedRows, pinnedRows } = createClientRows(clients.data, getFavoriteClients());
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: formedRows,
        allClients: formedRows,
        total: formedRows.length,
        favoriteClients: pinnedRows,
      }));
    };
    // if we haven't fetched data yet but we're authenticated & ready
    // then we should make the api call
    if (!hasFetchedData.current && authenticated) {
      fetchClients()
        .then(() => {
          hasFetchedData.current = true;
        })
        .catch((e) => {
          logger.log(e);
        });
    }
  }, [authenticated]);

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    // eslint-disable-next-line max-len
    const filteredRows = pageState.allClients.filter((row) => Object.keys(row).some((field) => searchRegex.test(row[field])));
    setPageState((old) => ({
      ...old,
      total: filteredRows.length,
      data: filteredRows,
    }));
  };

  const handleRowClick = (event) => {
    navigate(`/client/${event.row.code}`, {
      replace: false,
      state: { client: clientData[0][event.row.id] },
    });
  };

  const getDefaultColumns = () => columns;

  return (
    <div data-testid="list">
      <div id="clientlist-header">
        <div className="header-left">
          <h2>Client List</h2>
          {hasClientCreateEditPermission && (
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                navigate('/newclient');
              }}
            >
              Add New Client
            </Button>
          )}
        </div>
        <div className="header-right">
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(event) => requestSearch(event.target.value)}
            size="small"
          />
        </div>
      </div>
      <Table
        rowClickFunc={handleRowClick}
        pageState={pageState}
        updatePageState={setPageState}
        columns={orderedColumns}
        sortModel={sortModel}
        setSortModel={setSortModel}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        pinnedColumns={{ pinnedColumns: { left: ['settings', 'favorite'] } }}
        tablePrefix="clientlist"
        setOrderedColumns={setOrderedColumns}
        getDefaultColumns={getDefaultColumns}
        customSetOrdered={customSetOrdered}
      />
    </div>
  );
}

export default ClientList;

ClientList.propTypes = {
  setHeader: PropTypes.func,
  hasTDCAdminPermission: PropTypes.bool,
  hasDataEngineerPermission: PropTypes.bool,
  hasAnalystPermission: PropTypes.bool,
};
